<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="rubric_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-rubric-filter></app-rubric-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="rubrics"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import RubricFilter from '../../components/filter/FilterRubric'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'
import PermissionService from '@/services/PermissionService'
import IconCheck from '@/assets/img/svg/check.svg'

export default {
  name: 'RubricListView',
  data () {
    return {
      IconCheck,
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('rubric.list.id'),
          title: this.$t('rubric.list.title'),
          isVirtualized: this.$t('rubric.list.isVirtual'),
          site: this.$t('rubric.list.site'),
          parent: this.$t('rubric.list.parent'),
          sorting: this.$t('rubric.list.sorting'),
          routes: this.$t('rubric.list.absolute_url')
        },
        actions: {
          edit: 'rubric_edit'
        },
        render: {
          isVirtualized: function (data) {
            return data ? 'showCheckmark' : ''
          },
          routes: function (data) {
            if (data) {
              return data[0]?.absoluteUrl
            }
          },
          parent: function (data) {
            if (data) {
              return data
            }
            return '-'
          },
          site: (id) => this.getSiteTitle(id)
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_RUBRIC_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    rubrics () {
      return this.$store.getters['rubric/list']
    },
    totalCount () {
      return this.$store.getters['rubric/totalCount']
    },
    page () {
      return this.$store.getters['rubric/page']
    },
    sites () {
      return this.$store.getters['site/all']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appRubricFilter: RubricFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getRubrics () {
      this.$store.dispatch('rubric/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getSiteTitle (id) {
      const site = this.sites.find((site) => site.id === id)
      if (site === undefined) return id
      return site.title
    },
    setPageAndGetRecords (page) {
      this.$store.commit('rubric/setPage', page)
      this.getRubrics()
    }
  },
  mounted () {
    this.getRubrics()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
